import Head from 'next/head';
import React from 'react';

const Meta = () => (
  <Head>
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta charSet="utf-8" />
    <meta name="description" content="Ellingson App Portal" />
    <link rel="icon" type="image/png" sizes="96x96" href="logo.png" />
    <title>Ellingson App Portal</title>
  </Head>
);

export default Meta;
