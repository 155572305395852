import axios from 'axios';
import axiosRetry from 'axios-retry';
import LogRocket from 'logrocket';
import React, { ReactNode, useEffect, useRef } from 'react';
import { useAuth } from '../context/authContext';
import OverlayLoading from './common/OverlayLoading';


const loadAuthenticatedApp = () => import('./AuthenticatedApp');
const AuthenticatedApp = React.lazy(loadAuthenticatedApp);
const UnauthenticatedApp = React.lazy(() => import('./UnauthenticatedApp'));

interface Props {
  children: ReactNode;
}

function Page({ children }: Props): JSX.Element {
  const { user } = useAuth();


  // pre-load the authenticated side in the background while the user's
  // filling out the login form.
  useEffect(() => {
    loadAuthenticatedApp();
  }, []);

  axiosRetry(axios, {
    retries: 3, // number of retries
    retryDelay: (retryCount) => {
      return retryCount * 2000; // time interval between retries
    },
  });

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_NODE_ENV === 'production') {
      LogRocket.init('drlk3k/ellingson-maps');
    }
  });


  return (
    <React.Suspense fallback={<OverlayLoading />}>
      {user.auth ? <AuthenticatedApp>{children}</AuthenticatedApp> : <UnauthenticatedApp />}
    </React.Suspense>
  );
}

export default Page;
