import { onIdTokenChanged, User as FirebaseUser } from '@firebase/auth';
import { Auth, AuthError, ParsedToken } from 'firebase/auth';
import { collection, doc } from 'firebase/firestore';
import { createContext, useContext, useEffect, useMemo } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDocument } from 'react-firebase-hooks/firestore';
import { AppUser } from '../../custom';
import OverlayLoading from '../components/common/OverlayLoading';
import { db } from '../lib/db';
import fbAuth from '../lib/fbAuth';
import useLoadingValue from '../lib/useLoadingValue';
import { LoadingHook } from '../lib/utils';

export type AuthTokenHook = LoadingHook<{ token: string; claims: ParsedToken } | null, AuthError>;

function useFirebaseToken(auth: Auth): AuthTokenHook {
  const { error, loading, setError, setValue, value } = useLoadingValue<
    { token: string; claims: ParsedToken } | null,
    AuthError
  >();

  useEffect(() => {
    const listener = onIdTokenChanged(
      auth,
      (user) => {
        if (user) {
          user.getIdTokenResult().then((res) => {
            setValue({ token: res.token, claims: res.claims });
          });
        } else {
          setValue(null);
        }
      },
      (error) => {
        setError;
      }
    );
    return () => {
      listener();
    };
  }, [auth]);

  return useMemo<AuthTokenHook>(() => [value, loading, error], [error, loading, value]);
}

type FirebaseAuthHook = [value: User, loading: boolean, error: boolean];

interface User {
  auth: FirebaseUser | null;
  info: AppUser | null;
  token: string | null | undefined;
  claims: ParsedToken | null | undefined;
}

function useFirebaseAuth(): FirebaseAuthHook {
  const [fbUser, fbUserLoading, fbUserError] = useAuthState(fbAuth);
  const [userInfo, userInfoLoading, userInfoError] = useDocument(
    fbUser && doc(collection(db, 'users'), fbUser.uid)
  );
  const [value, valueLoading, valueError] = useFirebaseToken(fbAuth);

  if (fbUserLoading || userInfoLoading || valueLoading) {
    return [{ auth: null, info: null, token: undefined, claims: undefined }, true, false];
  }

  if (fbUser && userInfo && value?.token) {
    return [
      {
        auth: fbUser,
        info: userInfo.data() as AppUser,
        token: value?.token,
        claims: value?.claims,
      },
      false,
      false,
    ];
  }

  if (!fbUser) {
    return [{ auth: null, info: null, token: undefined, claims: undefined }, false, false];
  }

  if (fbUserError || userInfoError || valueError) {
    return [{ auth: null, info: null, token: undefined, claims: undefined }, false, true];
  }

  return [{ auth: null, info: null, token: undefined, claims: undefined }, false, false];
}

interface AuthContext {
  user: {
    auth: FirebaseUser | null;
    info: AppUser | null;
    token: string | null | undefined;
    claims: ParsedToken | null | undefined;
  };
}

const AuthContext = createContext<AuthContext | undefined>(undefined);

const AuthProvider: React.FC = ({ children }) => {
  const [user, isLoading, isError] = useFirebaseAuth();

  const value = useMemo(() => ({ user }), [user]);

  if (isLoading) {
    return <OverlayLoading />;
  }

  if (isError) {
    return <div>error</div>;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

function useAuth(): AuthContext {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
