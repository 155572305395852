import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const OverlayLoading = (): JSX.Element => {
  return (
    <div className="w-full h-full fixed flex items-center justify-center top-0 left-0 bg-black opacity-75 z-50">
      <FontAwesomeIcon className="text-white opacity-75" icon="spinner" spin size="5x" />
    </div>
  );
};

export default OverlayLoading;
