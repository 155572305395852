import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  fromPromise,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from 'apollo-link-error';
import fbAuth from '../lib/fbAuth';

const httpLink = createHttpLink({
  uri: `${process.env.NEXT_PUBLIC_USER_GRAPHQL}`,
});

const getNewToken = async () => {
  return await fbAuth.currentUser?.getIdToken();
};
//force refresh token
const getRefreshedToken = async () => {
  return await fbAuth.currentUser?.getIdToken(true);
};

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }): any => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      switch (err.extensions.code) {
        case 'UNAUTHENTICATED':
          return fromPromise(
            getNewToken().catch((error) => {
              // Handle token refresh errors e.g clear stored tokens, redirect to login
              return;
            })
          )
            .filter((value) => Boolean(value))
            .flatMap((accessToken): any => {
              const oldHeaders = operation.getContext().headers;
              // modify the operation context with a new token
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  authorization: `Bearer ${accessToken}`,
                },
              });
              // retry the request, returning the new observable
              return forward(operation);
            });

        case 'UNSUBSCRIBED':
          return fromPromise(
            getRefreshedToken().catch((error) => {
              // Handle token refresh errors e.g clear stored tokens, redirect to login
              return;
            })
          )
            .filter((value) => Boolean(value))
            .flatMap((accessToken): any => {
              const oldHeaders = operation.getContext().headers;
              // modify the operation context with a new token
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  authorization: `Bearer ${accessToken}`,
                },
              });
              // retry the request, returning the new observable
              return forward(operation);
            });
      }
    }
  }
});
const authLink = setContext(async (_, { headers }) => {
  const idToken = await fbAuth.currentUser?.getIdToken();
  return {
    headers: {
      ...headers,
      authorization: idToken ? `Bearer ${idToken}` : '',
    },
  };
});

const apolloGraphClient = new ApolloClient({
  link: ApolloLink.from([errorLink as unknown as ApolloLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});

export default apolloGraphClient;
