import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where
} from 'firebase/firestore';
import { GeoJsonFeature } from '../../custom';

const config = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
};
const app = initializeApp(config);

const auth = getAuth(app);

export const db = getFirestore(app);
// db.useEmulator('localhost', 8080);

export const setFeature = async (data: GeoJsonFeature) => {
  const finalData = {
    ...data,
    data: {
      ...data.data,
      geometry: JSON.stringify(data.data.geometry),
    },
  };
  await setDoc(doc(collection(db, 'features'), finalData.id), finalData);
};

export const deleteFeature = async (featureId: string) => {
  await deleteDoc(doc(collection(db, 'features'), featureId));
};

export const getFeatures = async (uid: string) => {
  await getDocs(query(collection(db, 'features'), where('owner', 'array-contains', uid)));
};

export type UserInfo = {
  firstName: string;
  lastName: string;
  companyName: string;
  phoneNumber: string;
  userType: string;
};

export const setUserInfo = async (uid: string, userInfo: UserInfo): Promise<void> => {
  return await setDoc(doc(collection(db, 'users'), uid), { ...userInfo }, { merge: true });
};

export const getUserInfo = async (): Promise<any> => {
  const user = auth.currentUser;
  return await getDoc(doc(collection(db, 'users'), user?.uid));
};

export const getUserInfoById = async (uid: string | undefined): Promise<any> => {
  return await getDoc(doc(collection(db, 'users'), uid));
};
